// js
import { useContext, useEffect, useState } from "react";
import { Card, InputGroup, FormControl, Row, Col, Button, Spinner } from "react-bootstrap";
import common from "../utilities/common";
import apiCaller from "../utilities/apiCaller";
import cookieManager from "../utilities/cookiesManager";
import { toast } from "react-toastify";

// hr context
import { HumanResourceDataCardContext } from "../components/HumanResourceDataCard";

/**
 * feedback component
 * @param {boolean} show determine wheter to show or not
 * @param {object} feedbackHistory feedback state
 * @param {string} feedbackHistory.name feedback state
 * @param {string} feedbackHistory.email feedback state
 * @param {string} feedbackHistory.feedback feedback state
 * @param {callback} setFeedbackHistory set feedback state function
 * @returns {Feedback} feedback part
 */
function Feedback({ show, feedbackHistory, setFeedbackHistory }) {
    const context = useContext(HumanResourceDataCardContext);
    const fontColor = context.feedbackButtonStyleBgColor ?? common.orangeColor;

    const [style, setStyle] = useState({
        display: "none",
        transition: "opacity 400ms",
        opacity: 0,
    });

    const [sending, setSending] = useState(false);

    useEffect(() => {
        if (show == false) {
            setStyle({ ...style, opacity: 0 });
            setTimeout(() => {
                setStyle({ ...style, opacity: 0, display: "none" });
            }, 400);
        }
        else {
            setStyle({ ...style, display: null });
            setTimeout(() => {
                setStyle({ ...style, opacity: 1, display: null });
            }, 100);
        }
    }, [show]);

    const handleChange = (e) => {
        switch (e.target.id) {
            case "name":
                setFeedbackHistory({ ...feedbackHistory, name: e.target.value });
                return;
            case "email":
                setFeedbackHistory({ ...feedbackHistory, email: e.target.value });
                return;
            case "feedback":
                setFeedbackHistory({ ...feedbackHistory, feedback: e.target.value });
                return;
            default:
                return;
        }
    }

    const handleClick = async (e) => {
        if (e.target.name === "send"
            || e.target.innerText === "send") {
            //https://ihr-api.ilrdf.org.tw/api/v1/send_comments
            const loginData = JSON.parse(sessionStorage.getItem(`loginData`));

            const formData = new FormData();
            formData.append("auth_token", cookieManager.getCookieValue("humanresource"));
            formData.append("p_id", Number(feedbackHistory.id));
            formData.append("reply_name", feedbackHistory.name);
            formData.append("reply_mail", feedbackHistory.email);
            formData.append("comments", feedbackHistory.feedback);

            const options = {
                method: "POST",
                url: process.env.REACT_APP_URL + "/v1/send_comments", // `https://ihr-api.ilrdf.org.tw/api/v1/send_comments`,
                body: formData,
                config: { headers: { "Content-Type": 'multipart/form-data' }, },
            }

            setSending(true)

            const response = await apiCaller(options);

            if (response.code === 200) {
                setSending(false)
                common.toastEmmiter(`意見回覆提交成功`);
            }
            else {
                setSending(true)

                toast.info(`失敗: code=> ${response.code}, message=> ${response.msg}`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: undefined,
                    style: {
                        backgroundColor: `${"red"}`,
                    }
                });
            }
        }
    }

    return (
        <div style={style}>
            {/* 意見回饋 */}
            <hr></hr>
            <Card.Title>
                <Row>
                    <Col>
                        <span className={"material-icons"} style={{ color: fontColor }}>{"comment"}</span>&nbsp;&nbsp;<span style={{ color: fontColor }}>{"意見回饋"}</span>
                    </Col>
                </Row>
                <br></br>
            </Card.Title>
            <Card.Text>
                <Row>
                    <Col style={{ width: `12%`, flex: `0 0 auto` }}>
                        <label style={{ color: fontColor }}>{"姓                名"}</label>
                    </Col>
                    <Col md={4}>
                        <InputGroup>
                            <FormControl id="name" onChange={handleChange} value={feedbackHistory.name} style={{ backgroundColor: common.colorCode.no_F2F2F2 }} />
                        </InputGroup>
                    </Col>
                </Row>
            </Card.Text>
            <Card.Text>
                <Row>
                    <Col style={{ width: `12%`, flex: `0 0 auto` }}>
                        <span style={{ color: fontColor }}>{"Email(選填)"}</span>
                    </Col>
                    <Col md={4}>
                        <InputGroup>
                            <FormControl id="email" onChange={handleChange} value={feedbackHistory.email} style={{ backgroundColor: common.colorCode.no_F2F2F2 }} />
                        </InputGroup>
                    </Col>
                    <Col md={6}>
                        <span style={{ color: common.colorCode.no_BE9492, fontSize: ".8em" }}>{"若您有填寫email，審查人員會將審查結果透過Email的方式告知您，謝謝您寶貴的意見"}</span>
                    </Col>
                </Row>
            </Card.Text>
            <Card.Text>
                <Row>
                    <Col style={{ width: `12%`, flex: `0 0 auto` }}>
                        <label style={{ color: fontColor }}>{"意見回饋"}</label>
                    </Col>
                    <Col md={9}>
                        <InputGroup>
                            <FormControl id="feedback" as="textarea" aria-label="With textarea" onChange={handleChange} value={feedbackHistory.feedback} style={{ backgroundColor: common.colorCode.no_F2F2F2 }} />
                        </InputGroup>
                    </Col>
                    <Col md={1}>
                        <Button name={"send"} onClick={handleClick} style={{ color: common.BE9492, backgroundColor: context.badgeColor, borderStyle: "none" }}>
                            {
                                sending === true ? <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> : undefined
                            }
                            {"送出"}
                            <span className={"material-icons"}>{"send"}</span>
                        </Button>
                    </Col>
                </Row>
            </Card.Text>
        </div>
    )
}

export default Feedback;
