// bootstrap css
//import 'bootstrap/dist/css/bootstrap.min.css';

// custom scss including bootstrap scss
import "./assets/sass/custom.scss";

// css
//import "material-design-icons/iconfont/material-icons.css";
import "./assets/css/materialIcon.css";

// js
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "./App";
import ApplyApp from "./ApplyApp";
import Entry from "./Entry";
import ForgetPasswords from "./views/ForgetPasswordPage"

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route path="/application">
          <ApplyApp />
        </Route>
        <Route path="/forgetpassword">
          <ForgetPasswords />
        </Route>
        <Route path="/humanresource">
          <App />
        </Route>
        <Route path="/">
          <Entry />
        </Route>
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
