// custom css
import "../assets/css/backgroundLogin.css";

import logo from "../assets/img/logo.jpg";
import logoLogin from "../assets/img/logoLogin.jpg";
import SSLLogo from "../assets/img/SSLSeal.gif";

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Spinner,
  InputGroup,
  Button,
  Card,
  Navbar,
  Container,
  Row,
  Col,
  Table,
} from "react-bootstrap";
import FooterComponent from "../components/FooterComponent";
import common from "../utilities/common";
import apiCaller from "../utilities/apiCaller";
import cookieManager from "../utilities/cookiesManager";
import axios from "axios";
import { toast } from "react-toastify";

function ForgetPasswordPage() {
  document.body.style.backgroundColor = "#ffffff";

  //#region arrange string var
  const descriptionColor = `#5A493F`;
  const buttonColor = "#E75711";
  //#endregion

  const [account, setAccount] = useState("");
  const [email, setEmail] = useState("");
  const [sending, setSending] = useState(false);

  const resetPassword = async (e) => {
    try {
      setSending(true);

      const apiUrl = process.env.REACT_APP_URL;
      const routeResetPassword = process.env.REACT_APP_URL_RESET_PASSWORD;

      const formData = new FormData();
      formData.append("account", account);
      formData.append("email", email);

      const response = await axios.post(
        `${apiUrl}/${routeResetPassword}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response && response.status === 200) {
        common.toastEmmiter(
          "已將臨時密碼發送至您的信箱，請前往收信並依照信內指示登入系統並修改密碼。"
        );
      }
    } catch (error) {
      console.error(error);

      const response = error.response;
      const data = response.data;

      if (data.code === 401) {
        toast.warn(data.msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            backgroundColor: `${common.colorCode.no_7D8E96}`,
          },
        });
      } else {
        alert(error);
      }
    } finally {
      setSending(false);
    }
  };

  const inputOnChange = (e) => {
    if (e && e.target) {
      const inputValue = e.target.value;

      if (e.target.id === "account") {
        setAccount(inputValue);
      } else if (e.target.id === "email") {
        setEmail(inputValue);
      }
    }
  };

  return (
    <>
      <Navbar style={{ backgroundColor: "#fff" }}>
        <Container>
          <Navbar.Brand>
            <img src={`${logo}`} width={`100%`} height={`auto`} />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <br></br>

      <div style={{ margin: "auto", textAlign: "center", width: "50%" }}>
        <Row>
          <Col>
            <span className="material-icons-outlined">person</span>&nbsp;&nbsp;
            <div style={{ display: "inline-block", fontSize: "1.5rem" }}>
              {"帳號"}
            </div>
          </Col>
          <Col md={10}>
            <Form.Control
              onChange={inputOnChange}
              id={"account"}
              placeholder="請輸入帳號"
              aria-describedby="account"
              style={{ backgroundColor: "#DFDCDA", border: "none" }}
              type={`account`}
            />
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col>
            <span className="material-icons-outlined">email</span>&nbsp;&nbsp;
            <div style={{ display: "inline-block", fontSize: "1.5rem" }}>
              {"信箱"}
            </div>
          </Col>
          <Col md={10}>
            <Form.Control
              onChange={inputOnChange}
              id={"email"}
              placeholder="請輸入信箱"
              aria-describedby="email"
              style={{ backgroundColor: "#DFDCDA", border: "none" }}
              type={`email`}
            />
          </Col>
        </Row>
        <br></br>
        <div style={{ textAlign: "center" }}>
          <Button
            onClick={resetPassword}
            style={{
              width: "30%",
              borderRadius: "0.8rem",
              backgroundColor: buttonColor,
              border: "none",
            }}
            disabled={sending}
          >
            {sending && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {"寄送臨時密碼"}
          </Button>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <FooterComponent></FooterComponent>
      <br></br>
    </>
  );
}

export default ForgetPasswordPage;
