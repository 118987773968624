// js
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";

// utilities
import cookieManager from "./utilities/cookiesManager";
import apiCaller from "./utilities/apiCaller";

// views
import HumanResourceSearch from "./views/HumanResourceSearch";
import ConfirmHrDataTable from "./views/ConfirmHrDataTable";
import Login from "./views/Login";
import Instructions from "./views/Instructions";
import ChangePasswordPage from "./views/ChangePasswordPage";

// features
import common from "./utilities/common";

// components
import Announcement from "./components/Announcement";

// login context
export const LoginContext = React.createContext({});

function App() {
  // const for text use
  const HUMAN_RESOURCE_DATABASE = "原住民族語言人才資料庫";

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loginData, setLoginData] = useState(undefined);

  useEffect(async () => {
    if (cookie && !JSON.parse(sessionStorage.getItem("loginData"))) {
      // check session storage has login data
      // if not, request api and set login data to session storage
      common.loader.show();

      const formData = new FormData();
      formData.append(
        "auth_token",
        cookieManager.getCookieValue("humanresource")
      );

      const options = {
        method: "POST",
        url: process.env.REACT_APP_URL + "/v1/login_check", //  `https://ihr-api.ilrdf.org.tw/api/v1/login_check`,
        body: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      };

      const data = await apiCaller(options);

      if (data.data) {
        // mapping login data to loginData object, and set to sessionStorage named loginData
        const loginData = {
          user_id: data.data.user_id,
          display_name: data.data.display_name,
          account: data.data.account,
          name: data.data.name,
          email: data.data.email,
          ...data.data,
        };

        sessionStorage.setItem("loginData", JSON.stringify(loginData));

        setLoginData(loginData);
        setIsAuthorized(true);
      }

      common.loader.close();
    } else {
      setLoginData(JSON.parse(sessionStorage.getItem("loginData")));
    }
  }, []);

  // set auto logout timer
  const timerId = React.useRef(null);
  const timerId2 = React.useRef(null);
  useEffect(() => {
    if (!isAuthorized) {
      return;
    }

    clearTimeout(timerId.current);
    timerId.current = setTimeout(() => {
      timerId2.current = setTimeout(async () => {
        await cookieManager.setCookie(`humanresource`, ``);
        sessionStorage.clear();
        window.location.href = `/`;
      }, 5 * 1000);

      common.toastEmmiter(`閒置過久，請重新登入`, null, null, () => {
        clearTimeout(timerId2.current);
      });
    }, 1800 * 1000);

    return () => {
      clearTimeout(timerId.current);
    };
  });

  const cookie = cookieManager.getCookieValue("humanresource");

  if (!cookie) {
    return (
      <>
        {/* helmet */}
        <Helmet>
          <title>{HUMAN_RESOURCE_DATABASE}</title>
        </Helmet>
        <Login
          setLoginData={setLoginData}
          setIsAuthorized={setIsAuthorized}
        ></Login>
      </>
    );
  }

  return (
    <div style={{ fontFamily: common.MicrosoftJhengHei, height: "100%" }}>
      {/* helmet */}
      <Helmet>
        <title>{HUMAN_RESOURCE_DATABASE}</title>
      </Helmet>

      <LoginContext.Provider value={{ loginData: loginData }}>
        <HashRouter>
          <Switch>
            {/* login page */}
            <Route path="/login">
              <Login
                setLoginData={setLoginData}
                setIsAuthorized={setIsAuthorized}
              ></Login>
            </Route>

            {/* changepassword page */}
            <Route path="/changepassword">
              <ChangePasswordPage></ChangePasswordPage>
            </Route>

            {/* search page */}
            <Route exact path="/">
              <Redirect to={"/humanresourcesearch"}></Redirect>
            </Route>
            <Route path="/confirmhrdatatable">
              <ConfirmHrDataTable></ConfirmHrDataTable>
              {loginData?.old_account === "1" && (
                <Announcement
                  announcementText={`您的帳號使用期限剩餘${loginData.account_life}天，請盡快透過帳號申請平台申請新帳號。`}
                  accountLife={loginData.account_life}
                ></Announcement>
              )}
            </Route>
            <Route path="/humanresourcesearch">
              <HumanResourceSearch></HumanResourceSearch>
              {loginData?.old_account === "1" && (
                <Announcement
                  announcementText={`您的帳號使用期限剩餘${loginData.account_life}天，請盡快透過帳號申請平台申請新帳號。`}
                  accountLife={loginData.account_life}
                ></Announcement>
              )}
            </Route>

            {/* user instructions */}
            <Route path="/instructions">
              <Instructions></Instructions>
            </Route>
          </Switch>
        </HashRouter>
      </LoginContext.Provider>
    </div>
  );
}

export default App;
