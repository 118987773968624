// css
import "../assets/css/topBuffer.css";
import "../assets/sass/card.scss";

// js
import React from "react";
import { useContext, useEffect, useState } from "react";
import { Card, Row, Col, Badge, Button, Collapse } from "react-bootstrap";
import Feedback from "../components/Feedback";

// common
import common from "../utilities/common";

// Hr Context
import { HRContext } from "../views/HumanResourceSearch";

// export hr data card context
export const HumanResourceDataCardContext = React.createContext({});

/**
 * hr data card component
 * @param {object} data hr data object
 * @param {string} data.color hr data color
 * @param {string} data.title hr data title
 * @param {array} data.skills hr data skills
 * @param {string} data.address hr data address
 * @param {string} data.phone hr data phone
 * @returns {HumanResourceDataCard} hr data card tag
 */
function HumanResourceDataCard({ data }) {
  // use context
  const hrContext = useContext(HRContext);

  // hook state
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedbackHistory, setFeedbackHistory] = useState(data.feedback);

  //#region arrange var first
  const fontColor =
    data.sex == "1" ? common.colorCode.no_71836B : common.colorCode.no_BC9C64;
  const id = data.id;
  const title = data.title;
  const ch_name = data.ch_name;
  const clansman_name = data.clansman_name;
  const clansman_name_ch = data.clansman_name_ch;
  const dialect = data.dialect;
  const birth_year = data.birth_year;
  const sex = data.sex;
  const skill_type = data.skill_type;
  const skills = data.skills;

  let tempOpenSkills = [];
  for (let i = 0; i < skills.length; i++) {
    tempOpenSkills[skills[i].skill_id] = true;
  }

  const [openSkills, setOpenSkills] = useState(tempOpenSkills);
  const address = data.address;
  const permanent_addr = data.permanent_addr;
  const active_area = data.active_area;
  const tel = data.tel;
  const mobile = data.mobile;
  const email = data.email;
  const feedback = data.feedback;
  const service_agency = data.service_agency;
  const selected = data.selected;
  const advanced = Number(data.advanced);
  const pass_away = Number(data.pass_away);

  // badge color
  const badgeColorArray = [common.colorCode.no_C8846D];

  // seleted border setting
  const selectedBorder = `0.4em solid ${common.colorCode.no_7D8E96}`;
  const selectedBackgroundColor = `hsl(199, 46%, 85%)`;

  // feedback button style
  const feedbackButtonStyle = {
    float: "right",
    borderRadius: "25px",
    backgroundColor: common.colorCode.no_7D8E96,
    borderStyle: "none",
    width: "auto",
  };

  const cardStyle = {
    textDecoration: "none",
    color: "black",
    borderRadius: "25px",
    borderStyle: "none",
    cursor: "pointer",
    border: selected == true ? selectedBorder : ``,
    backgroundColor: selected == true ? selectedBackgroundColor : ``,
  };
  //#endregions

  // hook effect
  useEffect(() => {
    if (!showFeedback) return;

    // set hr data from props
    setShowFeedback(false);
  }, [data]);

  useEffect(() => {
    if (showFeedback) return;

    // take hr data array from hr context
    let tempData = [...hrContext.data];

    // take the hr data in HrContext by index of hrdata which is setting from props
    let tempHrData = tempData[data.index];

    // add new feedback to hr data
    tempHrData.feedback = feedbackHistory;

    // put it back to hr data array
    tempData[data.index] = tempHrData;

    // set data in HrContext ( humanResourceSearch component )
    hrContext.setData(tempData);
  }, [showFeedback]);

  /**
   * [deprecated]
   * 改成用 getSkillsV2 來顯示
   * @param {Array} skills
   */
  function getSkills(skills) {
    return skills.map((skill, index) => {
      return (
        <Row style={{ marginBottom: index !== skills.length - 1 ? "1em" : "" }}>
          <Col>
            <Card.Text key={index}>
              <span className={"material-icons"} style={{ color: fontColor }}>
                {"verified"}
              </span>
              &nbsp;&nbsp;<span>{`${skill}`}</span>
            </Card.Text>
          </Col>
          {index === skills.length - 1 ? (
            <Col md={3}>
              <Button
                name={"feedbackbutton"}
                onClick={handleFeedback}
                sm={3}
                style={feedbackButtonStyle}
              >
                <span className={"material-icons"}>{"comment"}</span>{" "}
                {"意見回饋"}
              </Button>
            </Col>
          ) : (
            <></>
          )}
        </Row>
      );
    });
  }

  function getSkillsV2(skills) {
    return skills.map((skill) => {
      // 只有一筆的話就不要開合功能
      if (skill.skill_list.length === 1) {
        return (
          <Row className={"my-2 px-2"}>
            <Col>
              <Row>
                <span
                  style={{ color: fontColor, width: "1%" }}
                  className="material-icons"
                >
                  verified
                </span>
                <span style={{ width: "auto" }}>{skill.skill_list[0]}</span>
              </Row>
            </Col>
          </Row>
        );
      }

      return (
        <>
          <Row className="my-1">
            <Button
              key={"skill_id_" + skill.skill_id}
              id={"skill_id_" + skill.skill_id}
              className="px-2"
              onClick={collapseControll}
              aria-controls={skill.skill_id}
              aria-expanded={openSkills[skill.skill_id]}
              style={{
                background: "transparent",
                borderStyle: "none",
                color: "black",
                width: "auto",
                color: fontColor,
              }}
            >
              {openSkills[skill.skill_id] === false ? (
                <span
                  id={"skill_id_" + skill.skill_id}
                  className="material-icons"
                >
                  add_circle_outline
                </span>
              ) : (
                <span
                  id={"skill_id_" + skill.skill_id}
                  className="material-icons"
                >
                  do_not_disturb_on
                </span>
              )}
              <span
                id={"skill_id_" + skill.skill_id}
                className="material-icons"
              >
                verified
              </span>
              <span
                id={"skill_id_" + skill.skill_id}
                style={{ color: "black" }}
              >
                {skill.skill_name}
              </span>
            </Button>
            <Collapse
              in={openSkills[skill.skill_id]}
              style={{ marginLeft: "1rem" }}
            >
              <div id={"skill_id_" + skill.skill_id}>
                {skill.skill_list.map((subSkill, index) => {
                  return (
                    <Row className="my-1">
                      <Col>
                        <Row>
                          <span
                            className={"material-icons"}
                            style={{ color: fontColor, width: "1%" }}
                          >
                            {"subdirectory_arrow_right"}
                          </span>
                          <span
                            style={{ color: fontColor, width: "1%" }}
                            className="material-icons"
                          >
                            verified
                          </span>
                          <span style={{ width: "auto" }}>{subSkill}</span>
                        </Row>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </Collapse>
          </Row>
        </>
      );
    });
  }

  function collapseControll(e) {
    if (e.target) {
      let skillId = e.target.id.match(/\d+/g)[0];
      skillId = Number(skillId);

      let tempOpenSkills = [...openSkills];

      if (tempOpenSkills[skillId]) {
        tempOpenSkills[skillId] = false;
      } else {
        tempOpenSkills[skillId] = true;
      }

      setOpenSkills(tempOpenSkills);
    }
  }

  function getBadges(skills) {
    let count = skills.length;

    let badgesStyleArray = [];

    for (let index = 0; index < count; index++) {
      let j = index % badgeColorArray.length;
      badgesStyleArray.push(badgeColorArray[j]);
    }

    return skills.map((skill, index) => {
      return (
        <Badge
          key={index}
          pill
          //bg={`${badgesStyleArray[index]}`}
          style={{
            backgroundColor: badgesStyleArray[index],
            float: "right",
            marginTop: "0.2em",
            marginRight: "0.2em",
          }}
        >{`${skill}`}</Badge>
      );
    });
  }

  function handleFeedback() {
    if (showFeedback == false) {
      setShowFeedback(true);
    } else {
      setShowFeedback(false);
    }
  }

  const handleClick = (e) => {
    // if this is a feedback button, then return
    if (
      e.target.name === "feedbackbutton" ||
      e.target.name === "send" ||
      e.target.tagName === "INPUT" ||
      e.target.tagName === "TEXTAREA"
    ) {
      return;
    }
    // if is feedback send button
    else if (
      e.target.tagName === "SPAN" &&
      e.target.className === "material-icons" &&
      e.target.innerText === "send"
    ) {
      return;
    }

    // take hr data array from hr context
    let tempData = [...hrContext.data];

    // take the hr data in HrContext by index of hrdata which is setting from props
    let tempHrData = tempData[data.index];

    if (tempHrData.selected) {
      tempHrData.selected = false;
    } else {
      tempHrData.selected = true;
    }

    // put it back to hr data array
    tempData[data.index] = tempHrData;

    hrContext.setData([...tempData]);

    let selectedData = JSON.parse(sessionStorage.getItem("selectedData"));
    if (selectedData) {
      if (tempHrData.selected) {
        selectedData.push(tempHrData);
      } else {
        const index = selectedData.findIndex(
          (data) => data.id == tempHrData.id
        );
        if (index != undefined) {
          selectedData.splice(index, 1);
        }
      }
    } else {
      selectedData = [];
      selectedData.push(tempHrData);
    }

    sessionStorage.setItem("selectedData", JSON.stringify(selectedData));
  };

  return (
    <HumanResourceDataCardContext.Provider
      value={{
        badgeColor: badgeColorArray[0],
        feedbackButtonStyleBgColor: feedbackButtonStyle.backgroundColor,
      }}
    >
      <Card
        as={"a"}
        onClick={handleClick}
        className={"dataCard"}
        style={cardStyle}
      >
        <Card.Body>
          {/* data */}
          <Row>
            <Col md={7}>
              {advanced === 1 ? (
                <Badge
                  bg={"success"}
                  style={{ marginTop: "0.2em", marginRight: "0.2em" }}
                >
                  {" "}
                  {"進階資料"}{" "}
                </Badge>
              ) : (
                <></>
              )}
              {pass_away === 1 ? (
                <Badge
                  bg={"danger"}
                  style={{ marginTop: "0.2em", marginRight: "0.2em" }}
                >
                  {" "}
                  {"已歿"}{" "}
                </Badge>
              ) : (
                <></>
              )}
              <Card.Title
                style={{
                  color: fontColor,
                }}
              >
                {title}
              </Card.Title>
            </Col>
            <Col md={5}>
              <div style={{ float: "right" }}>{getBadges(skill_type)}</div>
            </Col>
          </Row>
          {address ? (
            <Row
              className={"p-2 my-1"}
              style={{
                marginBottom: address ? "1em" : "",
              }}
            >
              <Col md={9}>
                <Card.Text>
                  <span
                    className={"material-icons"}
                    style={{ color: fontColor }}
                  >
                    {"home"}
                  </span>
                  &nbsp;&nbsp;<span>{`通訊地址： ${address}`}</span>
                </Card.Text>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          {permanent_addr || active_area ? (
            <Row
              className={"p-2 my-1"}
              style={{
                marginBottom: permanent_addr || active_area ? "1em" : "",
              }}
            >
              <Col md={9}>
                <Card.Text>
                  <span
                    className={"material-icons"}
                    style={{ color: fontColor }}
                  >
                    {"home"}
                  </span>
                  &nbsp;&nbsp;
                  {permanent_addr ? (
                    <span>{`戶籍地址： ${permanent_addr}`}</span>
                  ) : (
                    <></>
                  )}
                  {permanent_addr && active_area ? `,` : ``}
                  {active_area ? <span>{`${active_area}`}</span> : <></>}
                </Card.Text>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          {mobile || tel ? (
            <Row
              className={"p-2 my-1"}
              style={{
                marginBottom: mobile || tel ? "1em" : "",
              }}
            >
              <Col md={9}>
                <Card.Text>
                  <span
                    className={"material-icons"}
                    style={{ color: fontColor }}
                  >
                    {"call"}
                  </span>
                  &nbsp;&nbsp;
                  {mobile ? <span>{`${mobile}`}</span> : <></>}
                  {mobile && tel ? `,` : ``}
                  {tel ? <span>{`${tel}`}</span> : <></>}
                </Card.Text>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          {email ? (
            <Row
              className={"p-2 my-1"}
              style={{ marginBottom: email ? "1em" : "" }}
            >
              <Col md={9}>
                <Card.Text>
                  <span
                    className={"material-icons"}
                    style={{ color: fontColor }}
                  >
                    {"email"}
                  </span>
                  &nbsp;&nbsp;<span>{`${email}`}</span>
                </Card.Text>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Row className={"px-2"}>
            {/* skills 收合組合 */}
            {getSkillsV2(skills)}
          </Row>
          <Row>
            <Col>
              <Button
                name={"feedbackbutton"}
                onClick={handleFeedback}
                sm={3}
                style={feedbackButtonStyle}
              >
                <span className={"material-icons"}>{"comment"}</span>{" "}
                {"意見回饋"}
              </Button>
            </Col>
          </Row>
          <Feedback
            name={"feedback"}
            show={showFeedback}
            feedbackHistory={feedbackHistory}
            setFeedbackHistory={setFeedbackHistory}
          ></Feedback>
        </Card.Body>
      </Card>
    </HumanResourceDataCardContext.Provider>
  );
}

export default HumanResourceDataCard;
