/**
 * dependencies
 * 🔗 this file is depend on react-toastify
 */
import { toast } from "react-toastify";

var common = common || {};

common.colorCode = {};

common.colorCode.no_FFA500 = "#FFA500";
common.colorCode.no_7D8E96 = "#7D8E96";
common.colorCode.no_BE9492 = "#BE9492";
common.colorCode.no_BC9C64 = "#BC9C64";
common.colorCode.no_717171 = "#717171";
common.colorCode.no_AA7D24 = "#AA7D24";
common.colorCode.no_A3432D = "#A3432D";
common.colorCode.no_71836B = "#71836B";
common.colorCode.no_C18A12 = "#C18A12";
common.colorCode.no_C18A13 = "#C18A13";
common.colorCode.no_F2F2F2 = "#F2F2F2";
common.colorCode.no_C8846D = "#C8846D";
common.colorCode.no_DAC4B1 = "#DAC4B1";
common.colorCode.no_E75711 = "#E75711";
common.colorCode.no_E6DED3 = "#E6DED3";
common.colorCode.no_8E8E8E = "#8E8E8E";
common.colorCode.no_FFF4EA = "#fff4ea";
common.colorCode.no_FF0000 = "#FF0000";
common.colorCode.no_F4F4F4 = "#F4F4F4";
common.MicrosoftJhengHei = "Microsoft JhengHei";

common.permissionCheck = async (auth_token) => {
  try {
    const formData = new FormData();
    formData.append("auth_token", auth_token);

    const endpoint = process.env.REACT_APP_URL_Export_Permission_Check;

    const response = await fetch(endpoint, {
      method: "POST",
      body: formData,
    });

    if (response && response.status === 200) {
      const data = (await response.json()).data;

      if (data.allow === 1) {
        return true;
      } else {
        throw new Error(data.msg);
      }
    }
  } catch (error) {
    console.error(error);
  }

  return false;
};

// not Implemented
common.notImplemented = async () => {
  await alert("尚未實作");
};

common.toastEmmiter = async (
  text,
  position = "top-center",
  onClose = null,
  onClick = null
) => {
  toast.info(text, {
    position: position,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    closeButton: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    onClose: onClose,
    onClick: onClick,
    style: {
      backgroundColor: `${common.colorCode.no_7D8E96}`,
    },
  });
};

common.router = {};
common.router.hashRoute = {};
common.router.hashRoute.humanResourceSearch = `/#/humanresourcesearch`;

/**
 * display formData's ley and value
 * @param {FormData} formData the formdata you want to log in console
 */
common.logFormData = (formData) => {
  // Log the key/value pairs
  for (var pair of formData.entries()) {
    console.log(pair[0] + " - " + pair[1]);
  }
};

/**
 * debounce
 * @param {callback} callback The callbackfunc
 * @param {Number} wait ms
 * @param {Boolean} immediate
 * @returns
 */
common.debounce = (func, wait, immediate) => {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

// loader
var loader = loader || {};

//window.top.loader = loader;

loader.show = async function (color, speed, text) {
  if (!color) {
    color = `#000000`;
  } else {
    const regexRule =
      /(?:#|0x)(?:[a-f0-9]{3}|[a-f0-9]{6})\b|(?:rgb|hsl)a?\([^\)]*\)/gi;
    let match = color.match(regexRule);
    if (!match) {
      color = `#000000`;
    } else {
      color = match[0];
    }
  }

  if (!speed) {
    speed = 2;
  }

  /* Set the style */
  let css = "";
  css += `.centered{
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -6.25em;
    margin-left: -6.25em;
    z-index: 20;
  }`;
  css += `.loader{
    border:10px solid #f3f3f3;
    border-top:10px solid ${color};
    margin: auto;
    animation: spin ${speed}s linear infinite;
    width:100px;
    height:100px;
    border-radius:50%;
    opacity: 1.0;
  }`;
  css += `.textDiv{
  text-align: center;
  margin-top: 0.5em;
  padding: 0.5em;
  border-radius:0.25em;
  /*border: solid 1px black;*/
  background-color: #71836b;
  color: white;
  }`;
  css += `.background{
    background-color: #414141;
    opacity: 0.5;
    width:100%;
    height:100%;
    left: 0;
    top: 0;
    position: fixed;
    z-index: 10;
  }`;
  css += `@keyframes spin{
    0% { transform: rotate(0deg); }
    100% {transform: rotate(360deg);}
  }`;

  // take the css style from crm webresource
  let style = document.createElement("style");
  style.innerHTML = css;
  style.id = "loaderStyleId";
  document.head.appendChild(style);
  //========================
  // recommend to add window.top
  let topBody = document.body;
  let bodyContent = topBody.innerHTML;

  let backgroundDiv = document.createElement("div");
  backgroundDiv.setAttribute("id", "backgroundDiv");
  backgroundDiv.classList.add("background");
  topBody.appendChild(backgroundDiv);

  let loaderDiv = document.createElement("div");
  loaderDiv.setAttribute("id", "loaderDiv");
  loaderDiv.classList.add("centered");

  let spinner = document.createElement("div");
  spinner.classList.add("loader");
  loaderDiv.appendChild(spinner);

  let textDiv = document.createElement("div");
  textDiv.innerText = text ? text : "資料讀取中，請稍後";
  textDiv.classList.add("textDiv");
  loaderDiv.appendChild(textDiv);

  topBody.appendChild(loaderDiv);
};

loader.close = function () {
  let doc = document;
  let removeDiv = doc.getElementById("loaderDiv");
  removeDiv.remove();
  let backgroundDiv = doc.getElementById("backgroundDiv");
  backgroundDiv.remove();
  let loaderStyleId = doc.getElementById("loaderStyleId");
  loaderStyleId.remove();
};

common.loader = common.loader || loader;

export default common;
