import logoApplicationLogin from "./assets/img/logoApplicationLogin.jpg";

// js
import { useHistory } from "react-router-dom";
import { Button, Card, Navbar, Container } from "react-bootstrap";
import FooterComponent from "./components/FooterComponent";
import common from "./utilities/common";

// custom css
import "./assets/css/backgroundLogin.css";
import "./assets/sass/loginStyle.scss";

function EntryPage() {
  //#region arrange string var
  const description1 = `’aveoveoyʉ,`;
  const description2 = `歡迎使用`;
  const description3 = `「財團法人原住民族語言研究發展基金會原住民族語言人才資料庫」`;
  const description4 = `本資料庫依據原住民族語言發展法第13條第2項設立，目前僅開放中央及地方各機關單位、各族語言推動組織使用。`;

  const description4_1 = `原住民族語言人才資料庫帳號使用規則`;
  const description4_2 = `1.僅限於業務必要之範圍使用，不可另做他用。`;
  const description4_3 = `2.應以善良管理人之注意妥為保管及確保資料之安全，並請妥善保管帳號。`;
  const description4_4 = `3.族語人才資料之使用，應遵守個人資料保護法，如有違反應負全責。`;
  const description4_5 = `4.申請資料個人資料僅供本基金會建檔及行必要之聯繫，不另做他用。`;

  const description5 = `請務必遵守`;
  const description6 = `及相關規範妥善保管。`;

  const descriptionColor = `#5A493F`;
  const descriptionColor2 = `#E75711`;

  const contact1 = `若有帳號申請問題請洽：`;
  const contact2 = `林小姐`;
  const contactBackgroundColor = `#F6F6F6`;
  const contactColor = `#7D8E96`;
  const phone = `02-23418508#604`;
  const or = `或`;
  const email = `alik@ilrdf.org.tw`;
  //#endregion

  let history = useHistory();

  return (
    <>
      <Navbar style={{ backgroundColor: "#fff" }}>
        <Container>
          <Navbar.Brand>
            <img
              src={`${logoApplicationLogin}`}
              width={`100%`}
              height={`auto`}
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <br></br>
      <Container className={"justify-content-md-center"}>
        <Card
          style={{
            color: descriptionColor,
            backgroundColor: "#fff2da",
            border: "none",
          }}
        >
          <Card.Body>
            <Card.Text className={"text-center"}>{description1}</Card.Text>
            <Card.Text className={"text-center"}>
              {description2}
              <span style={{ color: descriptionColor2 }}>{description3}</span>
            </Card.Text>
            <Card.Text className={"text-center"}>{description4}</Card.Text>
            <Card.Text className={"text-center"}>{description4_1}</Card.Text>
            <Card.Text
              className={"text-center"}
              style={{ color: descriptionColor2, fontWeight: "bold" }}
            >
              {description4_2}
            </Card.Text>
            <Card.Text
              className={"text-center"}
              style={{ color: descriptionColor2, fontWeight: "bold" }}
            >
              {description4_3}
            </Card.Text>
            <Card.Text
              className={"text-center"}
              style={{ color: descriptionColor2, fontWeight: "bold" }}
            >
              {description4_4}
            </Card.Text>
            <Card.Text
              className={"text-center"}
              style={{ color: descriptionColor2, fontWeight: "bold" }}
            >
              {description4_5}
            </Card.Text>
            <Card.Text className={"text-center"}>
              {description5}{" "}
              <a
                href="https://law.moj.gov.tw/LawClass/LawAll.aspx?PCode=I0050021"
                target="_blank"
              >
                個資法
              </a>{" "}
              {description6}
            </Card.Text>
            <Card.Text
                as={"div"}
                style={{
                  backgroundColor: contactBackgroundColor,
                  border: "5px solid white",
                }}
                className={"text-center"}
            >
              <h6 style={{marginTop: "0.625em"}}>帳號申請資格問題請洽：</h6>
              <h6 style={{color: contactColor, marginBottom: "0.625em"}}>
                林小姐
                {` `}
                <span className="material-icons">call</span>
                {` `}
                02-23418508#604
                {` `}
                或來信
                {` `}
                <span className="material-icons">mail</span>
                {` `}
                <a style={{color: contactColor}} href="mailto:alik@ilrdf.org.tw">alik@ilrdf.org.tw</a>
              </h6>
              <h6 style={{marginTop: "0.625em"}}>系統操作、帳號申請認證問題：</h6>
              <h6 style={{color: contactColor, marginBottom: "0.625em"}}>
                張小姐
                {` `}
                <span className="material-icons">call</span>
                {` `}
                02-66058298#26
                {` `}
                或來信
                {` `}
                <span className="material-icons">mail</span>
                {` `}
                <a style={{color: contactColor}} href="mailto:ihr@ilrdf.org.tw">ihr@ilrdf.org.tw</a>
              </h6>
            </Card.Text>
          </Card.Body>
        </Card>
        <br></br>
        <div className="row">
          <div className="col"></div>
          <div className="col-8 text-center">
            <Button
                style={{
                  backgroundColor: common.colorCode.no_C18A13,
                  border: "none",
                  fontSize: "2rem",
                  margin: "0.5rem",
                }}
                onClick={() => {
                  history.push("/application/login");
                }}
            >
              我要申請帳號
            </Button>
            <Button
              style={{
                backgroundColor: common.colorCode.no_E75711,
                border: "none",
                fontSize: "2rem",
                margin: "0.5rem",
              }}
              onClick={() => {
                history.push("/humanresource");
              }}
            >
              我要查詢資料
            </Button>
          </div>
          <div className="col"></div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <FooterComponent></FooterComponent>
        <br></br>
      </Container>
    </>
  );
}

export default EntryPage;
