//js
import Layout from "../layout/layout";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { useEffect, useState } from "react";
import XLSX from "xlsx";
import { useHistory } from "react-router-dom";

// utilities
import cookieManager from "../utilities/cookiesManager";

// common
import common from "../utilities/common";

/**
 * Confirm HrData Table view
 * @param {Array} data data array including objects which are seleted
 * @returns {JSX} ConfirmHrDataTable
 */
function ConfirmHrDataTable({ data }) {
  const BACK = `回上一頁`;
  const EXPORT_EXCEL = `匯出 Excel`;

  let history = useHistory();

  const [selectedData, setSelectedData] = useState([]);
  const [permissionOk, setPermissionOk] = useState(false);

  useEffect(() => {
    isPermissionOk();

    let selectedData = JSON.parse(sessionStorage.getItem("selectedData"));
    if (selectedData) {
      setSelectedData(
        selectedData.sort((a, b) => {
          return a.id - b.id;
        })
      );
    } else {
      window.location.href = `/humanresource#/humanresourcesearch`;
    }
  }, []);

  /**
   * 會檢查是否為一般使用者，permission === "1"
   * @returns {Boolean}
   */
  const isPermissionOk = async () => {
    const ok = await common.permissionCheck(
      cookieManager.getCookieValue("humanresource")
    );

    setPermissionOk(ok);
  };

  const goBack = () => {
    sessionStorage.setItem("selectedData", JSON.stringify([]));
    window.location.href = `/humanresource#/humanresourcesearch`;
  };

  const exportExcel = async () => {
    common.loader.show();

    const table = document.getElementById("table");
    const worksheet = XLSX.utils.table_to_sheet(table);

    let cols = Object.keys(selectedData[0]).map((key) => {
      return { width: 20 };
    });

    worksheet["!cols"] = cols;

    const workbook = XLSX.utils.book_new();
    workbook.Sheets = { "HR data": worksheet };
    workbook.SheetNames = ["HR data"];

    const workbookOut = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "binary",
    });
    const fileName = `原住民族語言人才資料庫_${getDateTime()}.xlsx`;
    const url = window.URL || window.webkitURL;
    const link = url.createObjectURL(
      new Blob([s2ab(workbookOut)], { type: "application/octet-stream" })
    );
    const a = document.createElement("a");
    a.setAttribute("download", fileName);
    a.setAttribute("href", link);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    common.loader.close();

    function s2ab(s) {
      //convert s to arrayBuffer
      var buf = new ArrayBuffer(s.length);

      //create uint8array as viewer
      var view = new Uint8Array(buf);

      //convert to octet
      for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;

      return buf;
    }
  };

  const getDateTime = () => {
    const dateObj = new Date();
    const month = padLeft(dateObj.getMonth() + 1, 2); //months from 1-12
    const day = padLeft(dateObj.getDate(), 2);
    const year = dateObj.getFullYear();

    return `${year}${month}${day}`;
  };

  const getTable = () => {
    let thStyle = {
      position: "sticky",
      top: "0",
      zIndex: "1",
      backgroundColor: "#AAAAAA",
    };

    let experts = JSON.parse(sessionStorage.getItem("expert"));

    if (!permissionOk) {
      return (
        <>
          <Table
            responsive="sm"
            id={"table"}
            striped
            bordered
            hover
            style={{
              backgroundColor: "white",
              display: "block",
              overflow: "scroll",
              whiteSpace: "nowrap",
              height: "20em",
              width: "92%",
              margin: "auto",
            }}
          >
            <thead>
              <tr>
                {["漢名", "語言別", "年齡", "地址"].map((item) => {
                  return (
                    <th key={item} style={thStyle}>
                      {item}
                    </th>
                  );
                })}
                {experts.map((skill) => (
                  <th key={skill.skill_id} style={thStyle}>
                    {skill.skill_name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {selectedData
                .sort((a, b) => {
                  return a.id - b.id;
                })
                .map((element) => {
                  let age = "無資料";
                  if (
                    element.birth_year != undefined &&
                    element.birth_year != "" &&
                    element.birth_year != 0
                  ) {
                    age = new Date().getFullYear() - element.birth_year;
                  }
                  console.log(element);
                  return (
                    <tr key={element.id}>
                      <td>{nameMasker(element.ch_name)}</td>
                      <td>{element.dialect}</td>
                      <td>{age}</td>
                      <td>{element.address_print}</td>
                      {experts.map((skill) => (
                        <td key={skill.skill_id}>
                          {element.skill_ids.includes(parseInt(skill.skill_id))
                            ? "有"
                            : "否"}
                        </td>
                      ))}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </>
      );
    }

    return (
      <Table
        responsive="sm"
        id={"table"}
        striped
        bordered
        hover
        style={{
          backgroundColor: "white",
          display: "block",
          overflow: "scroll",
          whiteSpace: "nowrap",
          height: "20em",
          width: "92%",
          margin: "auto",
        }}
      >
        <thead>
          <tr>
            {[
              "編號",
              "漢名",
              "族名",
              "族名漢字",
              "語言別",
              "年齡",
              "性別",
              "聯絡地址",
              "戶籍地址",
              "活動地區",
              "家用電話",
              "手機",
              "信箱",
              "服務機關",
              "專才",
            ].map((item) => {
              return (
                <th key={item} style={thStyle}>
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {selectedData
            .sort((a, b) => {
              return a.id - b.id;
            })
            .map((element) => {
              let age = "無資料";
              if (
                element.birth_year != undefined &&
                element.birth_year != "" &&
                element.birth_year != 0
              ) {
                age = new Date().getFullYear() - element.birth_year;
              }

              return (
                <tr key={element.id}>
                  <td>{element.id}</td>
                  <td>{element.ch_name}</td>
                  <td>{element.clansman_name}</td>
                  <td>{element.clansman_name_ch}</td>
                  <td>{element.dialect}</td>
                  <td>{age}</td>
                  <td>{element.sex == "1" ? "男" : "女"}</td>
                  <td>{element.address}</td>
                  <td>{element.permanent_addr}</td>
                  <td>{element.active_area}</td>
                  <td>{element.tel}</td>
                  <td>{element.mobile}</td>
                  <td>{element.email}</td>
                  <td>{element.service_agency}</td>
                  <td>
                    {element.skills.map((x) => {
                      return (
                        <>
                          {x.skill_list.map((skill) => (
                            <>
                              {`${skill}\r\n`}
                              <br />
                            </>
                          ))}
                        </>
                      );
                    })}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  };

  const nameMasker = (name) => {
    const array = Array.from(name);

    array[1] = "O";

    return array.join("");
  };

  const padLeft = (targetNumber, length) => {
    if (!length) {
      return "" + targetNumber;
    }

    targetNumber = "" + targetNumber;

    if (targetNumber.length >= length) {
      return targetNumber;
    } else {
      return padLeft("0" + targetNumber, length);
    }
  };

  return (
    <>
      <Layout>
        <Container responsive="sm">
          <br></br>
          <div>
            <Button
              onClick={goBack}
              style={{
                backgroundColor: common.colorCode.no_7D8E96,
                border: "none",
              }}
            >
              {BACK}
            </Button>
            {` `}
            <Button
              onClick={exportExcel}
              style={{
                backgroundColor: common.colorCode.no_A3432D,
                border: "none",
              }}
            >
              {EXPORT_EXCEL}
            </Button>
            {` `}
          </div>
        </Container>
        <br></br>
        <div>{getTable()}</div>
      </Layout>
    </>
  );
}

export default ConfirmHrDataTable;
