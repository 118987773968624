// common
import common from "../utilities/common";

// js
import React from "react";
import { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
  Container,
} from "react-bootstrap";
import DropDownList from "./DropDownList";
import DropDownListButtonGroup from "./DropDownListButtonGroup";
import apiCaller from "../utilities/apiCaller";

// utilities
import uuidv4 from "../utilities/uuidGenerator";
import cookieManager from "../utilities/cookiesManager";

// mock axios
import axios from "../mock/mockAxios";

// export hr data card context
export const FilterAndSearchContext = React.createContext({});

/**
 * filter condition and search button
 * @param {callback} setApiData data array which involved index and feedback from api
 * @param {callback} setDisplayData display data
 * @param {callback} setMessage display message
 * @returns {JSX} FilterAndSearchUpperCard
 */
function FilterAndSearchUpperCard({
  setData,
  setMessage,
  setActive,
  openDescription,
  setOpenDescription,
}) {
  const labelTextRace = "族群別";
  const labelTextDialect = "語言別";
  const labelTextHrCategory = "人才類別";
  const labelTextCity = "城市";
  const labelTextRegion = "鄉鎮市區";
  const labelTextSpecialSkill = "特殊專長或技能";
  const labelTextYear = "年份";
  const SEARCH_CONDITION = `搜尋條件`;
  const ADD_REGION_CONDITION = `新增地區條件`;
  const ADD_SKILL_CONDITION = `新增專才條件`;

  const hrCategory = "";

  // special skills array
  const specialSkills = ["族語認證初級", "族語認證中級", "族語認證高級"];

  // symbol style
  const symbolStyle = {
    backgroundColor: common.colorCode.no_AA7D24,
    color: "#FFF",
    textDecoration: "none",
    borderRadius: "3px",
    width: "1.2em",
    height: "1.2em",
    display: "inline-block",
    textAlign: "center",
    lineHeight: "1.2em",
    fontSize: "1.2em",
    //fontWeight: "bold"
  };

  //📌 hook state
  // key word (7 words)
  const [keyword, setKeyword] = useState("");

  //📌 region and expert
  // select list condition group array
  const [regionGroupArray, setRegionGroupArray] = useState([]);
  const [skillConditionsGroupArray, setSkillConditionsGroupArray] = useState(
    []
  );

  // added conditions will be save these vars
  const [selectedLocationArray, setSelectedLocationArray] = useState([]);
  const [selectedExpertArray, setSelectedExpertArray] = useState([]);

  // default values
  const [defaultValueLocation, setDefaultValueLocation] = useState(undefined);
  const [defaultValueExpert, setDefaultValueExpert] = useState(undefined);

  //📌 row1, language and dialect
  // selected value
  const [row1FirstSelect, setRow1FirstSelect] = useState(undefined);
  const [row1SecondSelect, setRow1SecondSelect] = useState(undefined);

  // dropdown item
  const [row1FirstDropdownItem, setRow1FirstDropdownItem] = useState(undefined);
  const [row1SecondDropdownItem, setRow1SecondDropdownItem] =
    useState(undefined);

  // default value
  const [row1FirstDefaultValue, setRow1FirstDefaultValue] = useState(undefined);
  const [row1SecondDefaultValue, setRow1SecondDefaultValue] =
    useState(undefined);

  //📌 requested data array will be save in these three array
  const [languageArray, setLanguageArray] = useState([]);
  const [locationArray, setLocationArray] = useState([]);
  const [expertArray, setExpertArray] = useState([]);

  // clear all selected conditions
  const [clearAll, setClearAll] = useState(false);

  // set default value to dropdownlist
  // dropdown list need a prop named default value for initialization
  useEffect(async () => {
    const searchCondition = JSON.parse(
      sessionStorage.getItem(`searchCondition`)
    );
    if (!searchCondition || searchCondition.length === 0) {
      return;
    }

    if (languageArray.length !== 0)
      setRow1FirstSelect(searchCondition.language.toString());
    if (languageArray.length !== 0)
      setRow1SecondSelect(searchCondition.dialect.toString());

    if (locationArray && locationArray.length !== 0) {
      if (searchCondition.location != undefined) {
        await handleAddConditionsGroup({
          target: { innerText: `+ 新增地區條件` },
        });
        setSelectedLocationArray(
          searchCondition.location != undefined ? searchCondition.location : []
        );
      }
    }

    if (expertArray && expertArray.length !== 0) {
      if (searchCondition.expert != undefined) {
        await handleAddConditionsGroup({
          target: { innerText: `+ 新增專才條件` },
        });
        setSelectedExpertArray(
          searchCondition.expert != undefined ? searchCondition.expert : []
        );
      }
    }

    setDefaultValueLocation(searchCondition.location);
    setDefaultValueExpert(searchCondition.expert);

    // set keyword
    setKeyword(searchCondition.keyword);
  }, [languageArray, locationArray, expertArray]);

  // request api, and take the data of select list about language, region, and skill
  useEffect(async () => {
    common.loader.show();

    const formData = new FormData();
    formData.append(
      "auth_token",
      cookieManager.getCookieValue("humanresource")
    );

    const options = {
      method: "POST",
      url: process.env.REACT_APP_URL_GET_SEARCH_PARAM, // `https://ihr-api.ilrdf.org.tw/api/v1/get_search_param`,
      body: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    };

    try {
      const response = await apiCaller(options);

      if (!response) {
        throw new Error(
          `request error => response is undefined or empty, response == ${response}`
        );
      }

      const searchCondition = JSON.parse(
        sessionStorage.getItem(`searchCondition`)
      );

      const language = response.data.language;

      if (language) {
        setLanguageArray(language);

        // if session data exist
        // need to present previous condition to user
        if (searchCondition && searchCondition.length !== 0) {
          setRow1FirstDropdownItem(
            language.map((x) => {
              return { id: x.language_id.toString(), name: x.language_name };
            })
          );

          const defaultLanguage = language.find((x) => {
            return x.language_id == searchCondition.language;
          });

          setRow1FirstSelect(defaultLanguage.language_id.toString());
          setRow1FirstDefaultValue(defaultLanguage.language_id.toString());

          if (defaultLanguage.dialect == undefined) {
            setRow1SecondDropdownItem([
              { dialect_id: "0", dialect_name: "全部" },
            ]);
            setRow1SecondSelect("0");
            setRow1SecondDefaultValue("0");
          }

          setRow1SecondDropdownItem(
            defaultLanguage.dialect.map((x) => {
              return { id: x.dialect_id.toString(), name: x.dialect_name };
            })
          );

          const defaultDialect = defaultLanguage.dialect.find((x) => {
            return x.dialect_id.toString() == searchCondition.dialect;
          });

          setRow1SecondSelect(defaultDialect.dialect_id.toString());
          setRow1SecondDefaultValue(defaultDialect.dialect_id.toString());
        } else {
          setRow1FirstDropdownItem(
            language.map((x) => {
              return { id: x.language_id.toString(), name: x.language_name };
            })
          );
          setRow1FirstSelect(language[0].language_id.toString());
          setRow1SecondDropdownItem(
            language[0].dialect.map((x) => {
              return { id: x.dialect_id.toString(), name: x.dialect_name };
            })
          );
          setRow1SecondSelect(language[0].dialect[0].dialect_id.toString());
        }
      }

      setLocationArray(response.data.location);
      setExpertArray(response.data.expert);

      sessionStorage.setItem("expert", JSON.stringify(response.data.expert));
    } catch (error) {
      console.log(error);
      alert(`${error.message} at FilterAndSearchUpperCard.js line 184`);
    } finally {
      common.loader.close();
    }
  }, []);

  // language select list row
  // when first one (left) be selected, change the second select list
  useEffect(() => {
    setRow1FirstDefaultValue(row1FirstSelect);

    const row1FirstSelectedObj = languageArray.find((x) => {
      return row1FirstSelect === x.language_id;
    });

    if (row1FirstSelectedObj == undefined) return;
    setRow1SecondSelect(row1FirstSelectedObj.dialect[0].dialect_id.toString());
    setRow1SecondDefaultValue(
      row1FirstSelectedObj.dialect[0].dialect_id.toString()
    );
    setRow1SecondDropdownItem(
      row1FirstSelectedObj.dialect.map((x) => {
        return { id: x.dialect_id.toString(), name: x.dialect_name };
      })
    );
  }, [row1FirstSelect]);

  useEffect(() => {
    if (clearAll) {
      setClearAll(false);
    }
  }, [clearAll]);

  // handle search button
  const search = async () => {
    common.loader.show();

    try {
      const formData = new FormData();
      formData.append(
        "auth_token",
        cookieManager.getCookieValue("humanresource")
      );
      formData.append("language", Number(row1FirstSelect));
      formData.append("dialect", Number(row1SecondSelect));
      if (keyword) formData.append("keyword", keyword);
      if (selectedLocationArray && selectedLocationArray.length != 0)
        formData.append("location", JSON.stringify(selectedLocationArray));
      if (selectedExpertArray && selectedExpertArray.length != 0)
        formData.append("expert", JSON.stringify(selectedExpertArray));

      const options = {
        method: "POST",
        url: process.env.REACT_APP_URL_GET_SEARCH_RESULT,
        body: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      };

      const response = await apiCaller(options);

      if (!response) {
        throw new Error(
          `request error => response is undefined or empty, response == ${response}`
        );
      }

      // **********************************************************************
      // [DEV] mock data for testing
      // const response = await axios.get(
      //     "https://my-json-server.typicode.com/s780609/JsonServer/hrdata"
      // );

      // const data = response.data;
      // **********************************************************************

      // set data back to parent hr search page
      // add index, selected and feedback props
      const loginData = JSON.parse(sessionStorage.getItem(`loginData`));
      response.data.map((element, index) => {
        element.index = index;
        element.feedback = {
          id: element.id,
          name: loginData.name,
          email: loginData.email,
          feedback: "",
        };
        element.selected = false;
      });

      // set message for toast card
      const message = {};
      message.condition = `${labelTextHrCategory}:${hrCategory}`;
      message.dataCount = response.num;
      message.description = `係指通過國家${specialSkills[0]}之個人`;

      const searchCondition = {};
      searchCondition.language = Number(row1FirstSelect);
      searchCondition.dialect = Number(row1SecondSelect);
      if (keyword) searchCondition.keyword = keyword;
      if (selectedLocationArray && selectedLocationArray.length != 0)
        searchCondition.location = selectedLocationArray;
      if (selectedExpertArray && selectedExpertArray.length != 0)
        searchCondition.expert = selectedExpertArray;

      // Save data to sessionStorage
      sessionStorage.setItem(
        "searchCondition",
        JSON.stringify(searchCondition)
      );
      sessionStorage.setItem("active", 1);
      sessionStorage.setItem("selectedData", JSON.stringify([]));
      sessionStorage.setItem("message", JSON.stringify(message));

      setMessage(message);
      setData(response.data);
      setActive(1);
    } catch (error) {
      console.log(error);
      alert(`${error.message} at FilterAndSearchUpperCard.js line 266`);
    } finally {
      common.loader.close();
    }
  };

  const handleKeyword = (e) => {
    let value;
    if (e.target.tagName === "A") {
      value = e.target.innerText;
      const input = document.getElementById("keyword");
      const newValue = input.value + value;

      input.value = newValue;
      setKeyword(newValue);
    } else {
      value = e.target.value;
      setKeyword(value);
    }
  };

  // add region and expert condition groups
  // this section code can move to useEffect hook that array is empty
  const handleAddConditionsGroup = async (e) => {
    if (
      e.target.innerText.trim().includes(ADD_REGION_CONDITION) &&
      !locationArray &&
      locationArray.length === 0
    ) {
      common.toastEmmiter(`無相關地區條件`);
      return;
    } else if (
      e.target.innerText.trim().includes(ADD_SKILL_CONDITION) &&
      !expertArray &&
      expertArray.length === 0
    ) {
      common.toastEmmiter(`無相關專才條件`);
      return;
    }

    let first;
    let second;
    let third;

    switch (e.target.innerText.replace(`+`, ``).trim()) {
      case ADD_REGION_CONDITION:
        first = {
          icon: "my_location",
          labelText: "地區",
          dropdownItem: locationArray.map((x) => {
            return { id: x.area_id.toString(), name: x.area_name };
          }),
          key: uuidv4(),
        };
        second = {
          icon: "place",
          labelText: labelTextCity,
          dropdownItem: locationArray.map((location) => {
            if (location.city == undefined) return null;
            return location.city.map((city) => {
              return { id: city.city_id.toString(), name: city.city_name };
            });
          }),
          key: uuidv4(),
        };
        third = {
          icon: "emoji_transportation",
          labelText: labelTextRegion,
          dropdownItem: locationArray.map((location) => {
            if (location.city == undefined) return null;
            return location.city.map((city) => {
              if (city.township == undefined) return null;
              return city.township.map((township) => {
                return {
                  id: township.township_id.toString(),
                  name: township.township_name,
                };
              });
            });
          }),
          key: uuidv4(),
        };

        setRegionGroupArray([first, second, third]);
        return;
      case ADD_SKILL_CONDITION:
        first = {
          icon: "stars",
          labelText: labelTextSpecialSkill,
          dropdownItem: expertArray.map((x) => {
            return { id: x.skill_id.toString(), name: x.skill_name };
          }),
          key: uuidv4(),
        };
        second = {
          icon: "chat",
          labelText: labelTextDialect,
          dropdownItem: expertArray.map((expert) => {
            if (expert.dialect == undefined) return null;
            return expert.dialect.map((dialect) => {
              return {
                id: dialect.dialect_id.toString(),
                name: dialect.dialect_name,
              };
            });
          }),
          key: uuidv4(),
        };
        third = {
          icon: "event_available",
          labelText: labelTextYear,
          dropdownItem: expertArray.map((expert) => {
            if (expert.dialect == undefined) return null;
            return expert.dialect.map((dialect) => {
              if (dialect.year == undefined) return null;
              return dialect.year.map((year) => {
                return { id: year.year.toString(), name: year.year_name };
              });
            });
          }),
          key: uuidv4(),
        };

        setSkillConditionsGroupArray([first, second, third]);
        return;
      default:
        console.log("no match condition");
        return;
    }
  };

  const handleClearAll = (e) => {
    setRow1FirstSelect("0");
    setRow1FirstDefaultValue("0");

    setSelectedLocationArray([]);
    setSelectedExpertArray([]);
    setClearAll(true);
  };

  return (
    <>
      <FilterAndSearchContext.Provider
        value={{
          selectedLocationArray: selectedLocationArray,
          setSelectedLocationArray: setSelectedLocationArray,
          selectedExpertArray: selectedExpertArray,
          setSelectedExpertArray: setSelectedExpertArray,
        }}
      >
        <Card>
          <Container>
            <Card.Body>
              <Row>
                <Col xs={4.5} md={3}>
                  <DropDownList
                    icon={"language"}
                    set={setRow1FirstSelect}
                    defaultValue={row1FirstDefaultValue}
                    labelText={labelTextRace}
                    dropdownItem={row1FirstDropdownItem}
                    width={"100%"}
                  ></DropDownList>
                </Col>
                <Col xs={4.5} md={3}>
                  <DropDownList
                    icon={"chat"}
                    set={setRow1SecondSelect}
                    defaultValue={row1SecondDefaultValue}
                    labelText={labelTextDialect}
                    dropdownItem={row1SecondDropdownItem}
                    width={"100%"}
                  ></DropDownList>
                </Col>
                <Col xs={4.5} md={6} style={{ fontFamily: "Times New Roman" }}>
                  <span
                    className={"material-icons"}
                    style={{ color: common.colorCode.no_AA7D24 }}
                  >
                    search
                  </span>
                  <label style={{ color: common.colorCode.no_AA7D24 }}>
                    {"關鍵字"}
                  </label>{" "}
                  &nbsp;&nbsp;
                  <a
                    href="javascript:void(0)"
                    onClick={handleKeyword}
                    style={symbolStyle}
                  >
                    {"^"}
                  </a>{" "}
                  <a
                    href="javascript:void(0)"
                    onClick={handleKeyword}
                    style={symbolStyle}
                  >
                    {"é"}
                  </a>{" "}
                  <a
                    href="javascript:void(0)"
                    onClick={handleKeyword}
                    style={symbolStyle}
                  >
                    {"ṟ"}
                  </a>{" "}
                  <a
                    href="javascript:void(0)"
                    onClick={handleKeyword}
                    style={symbolStyle}
                  >
                    {"ɨ"}
                  </a>{" "}
                  <a
                    href="javascript:void(0)"
                    onClick={handleKeyword}
                    style={symbolStyle}
                  >
                    {"ʉ"}
                  </a>{" "}
                  <a
                    href="javascript:void(0)"
                    onClick={handleKeyword}
                    style={symbolStyle}
                  >
                    {"’"}
                  </a>{" "}
                  <a
                    href="javascript:void(0)"
                    onClick={handleKeyword}
                    style={symbolStyle}
                  >
                    {":"}
                  </a>{" "}
                  <InputGroup className="mb-6">
                    <FormControl
                      id="keyword"
                      value={keyword}
                      onChange={common.debounce(handleKeyword, undefined, true)}
                      placeholder="請輸入關鍵字"
                    />
                  </InputGroup>
                </Col>
              </Row>
              <hr></hr>
              <Row>
                <Col xs={13.5} md={12}>
                  {/*dropdown list*/}
                  {regionGroupArray.length !== 0 ? (
                    <>
                      <DropDownListButtonGroup
                        category={"region"}
                        defaultValue={defaultValueLocation}
                        dropdownListArray={regionGroupArray}
                        conditionsQuantity={5}
                        setSelectedConditionsArray={setSelectedLocationArray}
                        clearButtonText={`清除全部地區條件`}
                        clearAll={clearAll}
                      ></DropDownListButtonGroup>
                      <hr></hr>
                    </>
                  ) : (
                    <></>
                  )}
                  {skillConditionsGroupArray.length !== 0 ? (
                    <DropDownListButtonGroup
                      category={"expert"}
                      defaultValue={defaultValueExpert}
                      dropdownListArray={skillConditionsGroupArray}
                      conditionsQuantity={5}
                      setSelectedConditionsArray={setSelectedExpertArray}
                      clearButtonText={`清除全部專才條件`}
                      clearAll={clearAll}
                    ></DropDownListButtonGroup>
                  ) : (
                    <></>
                  )}
                  {/* button */}
                  {locationArray.length !== 0 &&
                  regionGroupArray.length === 0 ? (
                    <Button
                      onClick={handleAddConditionsGroup}
                      style={{
                        color: common.colorCode.no_A3432D,
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <div
                        style={{
                          display: `flex`,
                          margin: `auto`,
                          textAlign: "center",
                          marginBottom: "0.25em",
                        }}
                      >
                        <div
                          style={{
                            fontSize: `2em`,
                            margin: `auto`,
                            textAlign: "center",
                          }}
                        >
                          <strong>{`+`}</strong>
                        </div>
                        <div
                          style={{
                            fontSize: `1em`,
                            textAlign: `left`,
                            margin: `auto`,
                            marginTop: `1em`,
                          }}
                        >
                          <>{ADD_REGION_CONDITION}</>
                        </div>
                      </div>
                    </Button>
                  ) : (
                    <></>
                  )}
                  {expertArray.length !== 0 &&
                  skillConditionsGroupArray.length === 0 ? (
                    <Button
                      onClick={handleAddConditionsGroup}
                      style={{
                        color: common.colorCode.no_A3432D,
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <div
                        style={{
                          display: `flex`,
                          margin: `auto`,
                          textAlign: "center",
                          marginBottom: "0.25em",
                        }}
                      >
                        <div
                          style={{
                            fontSize: `2em`,
                            margin: `auto`,
                            textAlign: "center",
                          }}
                        >
                          <strong>{`+`}</strong>
                        </div>
                        <div
                          style={{
                            fontSize: `1em`,
                            textAlign: `left`,
                            margin: `auto`,
                            marginTop: `1em`,
                          }}
                        >
                          <>{ADD_SKILL_CONDITION}</>
                        </div>
                      </div>
                    </Button>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={4.5} md={3}>
                  <Button
                    onClick={() => setOpenDescription(!openDescription)}
                    aria-controls="example-collapse-text"
                    aria-expanded={openDescription}
                    style={{
                      background: "transparent",
                      borderStyle: "none",
                      color: "black",
                      marginTop: "0.7em",
                    }}
                  >
                    {openDescription === false ? (
                      <p className="m-0 p-0">
                        <span className="material-icons">
                          arrow_circle_down
                        </span>
                        展開說明
                        <span className="material-icons">
                          arrow_circle_down
                        </span>
                      </p>
                    ) : (
                      <p className="m-0 p-0">
                        <span className="material-icons">arrow_circle_up</span>
                        關閉說明
                        <span className="material-icons">arrow_circle_up</span>
                      </p>
                    )}
                  </Button>
                </Col>
                <Col xs={4.5} md={3}>
                  {" "}
                </Col>
                <Col xs={4.5} md={3}>
                  <Button
                    size="lg"
                    style={{
                      marginTop: "0.7em",
                      width: "100%",
                      borderRadius: "25px",
                      backgroundColor: common.colorCode.no_DAC4B1,
                      borderStyle: "none",
                    }}
                    onClick={handleClearAll}
                  >
                    {" "}
                    {"X 清除所有搜尋條件"}
                  </Button>
                </Col>
                <Col xs={4.5} md={3}>
                  <Button
                    size="lg"
                    style={{
                      marginTop: "0.7em",
                      width: "100%",
                      borderRadius: "25px",
                      backgroundColor: common.colorCode.no_7D8E96,
                      borderStyle: "none",
                    }}
                    onClick={search}
                  >
                    {" "}
                    <span className={"material-icons"}>{"search"}</span>{" "}
                    {"搜尋"}
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Container>
        </Card>
      </FilterAndSearchContext.Provider>
    </>
  );
}

export default FilterAndSearchUpperCard;
