import logoApplicationLogin from "../../assets/img/logoApplicationLogin.jpg";

// js
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  InputGroup,
  Button,
  Card,
  Navbar,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import FooterComponent from "../../components/FooterComponent";
import common from "../../utilities/common";
import apiCaller from "../../utilities/apiCaller";
import cookieManager from "../../utilities/cookiesManager";
import { toast } from "react-toastify";

// custom css
import "../../assets/css/backgroundLogin.css";
import "../../assets/sass/loginStyle.scss";
import axios from "axios";

function ApplicationResetPasswordPage(props) {
  //#region arrange string var
  const descriptionColor = `#5A493F`;
  const buttonColor = "#E75711";
  //#endregion

  let history = useHistory();

  // hook state
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [mailToken, setMailToken] = useState(() => {
    if (props.match && props.match.params) {
      return props.match.params.mail_token;
    }
  });

  const [resetting, setResetting] = useState(false);

  const isPasswordMatchRule = (password) => {
    const matchResult = password.match(
      /^(?=.*[0-9])(?=.*[a-zA-Z])(?=[a-zA-Z0-9]{8})([a-zA-Z0-9]+)$/g
    );

    if (!matchResult) {
      return false;
    }

    return true;
  };

  const handleChange = (e) => {
    if (e && e.target) {
      const inputValue = e.target.value;

      if (e.target.id === "account") {
        setAccount(inputValue);
      } else if (e.target.id === "password") {
        setPassword(inputValue);
      } else if (e.target.id === "passwordCheck") {
        setPasswordCheck(inputValue);
      }
    }
  };

  const resetPassword = async (e) => {
    if (e.keyCode !== 13 && e.type !== "click") {
      return;
    }

    if (password !== passwordCheck) {
      common.toastEmmiter("密碼必須相同");

      return;
    }
    if (!isPasswordMatchRule(password) || !isPasswordMatchRule(passwordCheck)) {
      common.toastEmmiter("請使用英文與數字組合8位數以上之密碼");

      return;
    }

    setResetting(true);

    const url = process.env.REACT_APP_URL;
    const changePasswordRoute =
      process.env.REACT_APP_Application_Route_Change_Password;

    const formData = new FormData();
    formData.append("email", account);
    formData.append("password", password);
    formData.append("mail_token", decodeURIComponent(mailToken));

    const options = {
      method: "POST",
      url: `${url}/${changePasswordRoute}`,
      body: formData,
      config: {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    };

    try {
      const response = await axios.post(
        options.url,
        options.body,
        options.config
      );

      if (response && response.status === 200) {
        toast.info("重設成功", { position: "top-center" });

        history.push("/application/login");
      }
    } catch (error) {
      console.log(error.response)
      if (
        error.response &&
        error.response.data.errors &&
        error.response.data.errors.email.length > 0
      ) {
        const message = error.response.data.errors.email[0];
        toast.error(message, { position: "top-center" });
      } else if (error.response && error.response.data.code === 401) {
        toast.error(error.response.data.msg, { position: "top-center" });
      } else {
        console.error(error);
        alert(error.message);
      }
    } finally {
      setResetting(false);
    }
  };

  return (
    <>
      <Navbar style={{ backgroundColor: "#fff" }}>
        <Container>
          <Navbar.Brand>
            <img
              src={`${logoApplicationLogin}`}
              width={`100%`}
              height={`auto`}
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <br></br>
      <Container className={"justify-content-md-center"}>
        <Card className={"loginStyle text-center"}>
          <Card.Body>
            <Row>
              <Col md={2}></Col>
              <Col md={10} style={{ textAlign: "left" }}>
                <span style={{ color: common.colorCode.no_E75711 }}>
                  請輸入您註冊的信箱
                </span>
              </Col>
            </Row>
            <InputGroup className="mb-3">
              <InputGroup.Text
                style={{
                  color: descriptionColor,
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                <span className="material-icons-outlined">email</span>
                &nbsp;&nbsp;
                {"信箱"}
              </InputGroup.Text>
              <Form.Control
                onChange={handleChange}
                id={"account"}
                placeholder="請輸入信箱"
                aria-describedby="email"
                type="email"
                style={{ backgroundColor: "#DFDCDA", border: "none" }}
              />
            </InputGroup>
            <Row>
              <Col md={2}> </Col>
              <Col md={10} style={{ textAlign: "left" }}>
                <span style={{ color: common.colorCode.no_E75711 }}>
                  請使用英文與數字組合8位數以上之密碼
                </span>
              </Col>
            </Row>
            <InputGroup className="mb-3">
              <InputGroup.Text
                style={{
                  color: descriptionColor,
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                <span className="material-icons-outlined">lock</span>
                &nbsp;&nbsp;
                {"密碼"}
              </InputGroup.Text>
              <Form.Control
                onChange={handleChange}
                id={"password"}
                placeholder="請輸入密碼"
                aria-describedby="password"
                style={{ backgroundColor: "#DFDCDA", border: "none" }}
                type={`password`}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text
                style={{
                  color: "transparent",
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                <span className="material-icons-outlined">lock</span>
                &nbsp;&nbsp;
                {"密碼"}
              </InputGroup.Text>
              <Form.Control
                onChange={handleChange}
                onKeyUp={resetPassword}
                id={"passwordCheck"}
                placeholder="請再輸入一次密碼"
                aria-describedby="password"
                style={{ backgroundColor: "#DFDCDA", border: "none" }}
                type={`password`}
              />
            </InputGroup>
            <br></br>
            <div style={{ textAlign: "right" }}>
              <Button
                onClick={resetPassword}
                style={{
                  width: "30%",
                  borderRadius: "0.8rem",
                  backgroundColor: buttonColor,
                  border: "none",
                }}
                disabled={resetting}
              >
                {resetting && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {"重設密碼"}
              </Button>
            </div>
          </Card.Body>
        </Card>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <FooterComponent></FooterComponent>
        <br></br>
      </Container>
    </>
  );
}

export default ApplicationResetPasswordPage;
