import React from "react";
import { useRef, useState } from "react";
import { Spinner, Button } from "react-bootstrap";

/**
 * 🔗 dependencies: bootstrap5
 * Upload File Button
 * @param {string} id
 * @param {object} buttonStyle
 * @param {string} text
 * @param {string} accept
 * @param {function} onChange
 * @param {string} children
 * @returns
 */
function UploadFileButtonComponent({
  buttonStyle,
  text,
  accept,
  onClick,
  children,
}) {
  const inputRef = useRef();

  const [uploading, setUploading] = useState(false);

  const handleOnClick = () => {
    if (typeof onClick !== "function") {
      return;
    }

    inputRef.current.value = ""
    inputRef.current.click();
  };

  const handleOnChange = async (e) => {
    if (typeof onClick !== "function") {
      return;
    }

    setUploading(true);
    await onClick(e);
    setUploading(false);
  };

  return (
    <>
      <input
        type={"file"}
        ref={inputRef}
        onChange={handleOnChange}
        style={{ visibility: "hidden", width: "0", height: "0" }}
        accept={accept}
      ></input>
      <Button style={buttonStyle} onClick={handleOnClick}>
        {uploading === true ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : undefined}
        {text || children}
      </Button>
      {/* <label htmlFor={id} className="btn" style={buttonStyle}>
        {uploading === true ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : undefined}{" "}
        {text || children}
      </label>
      <input
        id={id}
        type={"file"}
        style={{ visibility: "hidden", width: "0", height: "0" }}
        accept={accept}
        onChange={fileInputOnChange}
      ></input> */}
    </>
  );
}

export default UploadFileButtonComponent;
