// js
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Container, Spinner, Accordion } from "react-bootstrap";
import ApplicationHeaderComponent from "../../components/ApplicationHeaderComponent";
import ApplicationCardComponent from "../../components/ApplicationCardComponent";
import ApplicationTableComponent from "../../components/ApplicationTableComponent";
import FooterComponent from "../../components/FooterComponent";
import common from "../../utilities/common";
import apiCaller from "../../utilities/apiCaller";
import cookieManager from "../../utilities/cookiesManager";

// custom css
import "../../assets/css/backgroundLogin.css";
import "../../assets/sass/loginStyle.scss";
import axios from "axios";

function ApplicationListPage() {
  let history = useHistory();

  const [hasApplication, setHasApplication] = useState(false);
  const [applications, setApplications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddButton, setShowAddButton] = useState(true);

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    applications.map((application) => {
      if (application.status < 5) {
        setShowAddButton(false);
      }
    });
  }, [applications]);

  useEffect(() => {
    if (!cookieManager.getCookieValue(`application`)) {
      history.push("/application/login");
    }
  });

  const getList = async () => {
    const url = process.env.REACT_APP_Application_Url;
    const getListRoute = process.env.REACT_APP_Application_Route_Get_List;

    const userToken = await getUserToken();

    if (!userToken) {
      history.push("/application/login");
      return;
    }

    try {
      setIsLoading(true);

      const formData = new FormData();
      formData.append("user_token", userToken);

      const response = await axios.get(
        `${url}/${getListRoute}?user_token=${userToken}`
      );

      if (response.status === 200) {
        console.log(response.data.applications);
        const applications = response.data.applications;
        if (applications && applications.length > 0) {
          setHasApplication(true);
          setApplications(applications);
        }
      }
    } catch (error) {
      console.error(error);
      alert(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const addApplication = () => {
    history.push("/application/form/create");
  };

  const getUserToken = async () => {
    const userToken = await cookieManager.getCookieValue(`application`);
    if (userToken) {
      return userToken;
    } else {
      return undefined;
    }
  };

  const renderList = () => {
    if (isLoading) {
      return undefined;
    }

    return applications.length > 0 ? (
      applications.map((application) => {
        return (
          <ApplicationTableComponent
            key={application.id}
            applicationNumber={`#${application.id}`}
            id={application.id}
            accountCustodian={application.keeper_ch}
            state={application.status.toString()}
            filePath={application.file_path}
            updatedTime={application.updated_at}
            denyReason={application.deny_reason}
            refreshParent={() => {
              getList();
            }}
          ></ApplicationTableComponent>
        );
      })
    ) : (
      <div style={{ textAlign: "center" }}>目前無申請單</div>
    );
  };

  return (
    <>
      <ApplicationHeaderComponent
        logoutButtonText={"登出"}
        onClickLogoutButton={() => {
          cookieManager.setCookie("application", "", 0);
          history.push("/application/login");
        }}
      ></ApplicationHeaderComponent>
      <br></br>
      <Container className={"justify-content-md-center"}>
        <Accordion  className="mb-5">
        <Accordion.Item>
          <Accordion.Header>帳號申請步驟說明</Accordion.Header>
          <Accordion.Body>
            <p>步驟1.新增並填寫申請單</p>
            <p>步驟2.確認內容填妥後，勾選同意後鎖定申請單(此時申請單狀態不可再變動)</p>
            <p>步驟3.等待初審通過</p>
            <p>步驟4.收到初審通過email後，登入系統下載申請單</p>
            <p>步驟5.申請單列印出來用印後掃描成PDF檔</p>
            <p>步驟6.回到系統內上傳掃描的PDF檔</p>
            <p>步驟7.等待複審通過</p>
            <p>步驟8.複審通過將會收到email，請依照信件指示開通人才資料庫系統帳號</p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
        <Card
          style={{
            backgroundColor: "white",
            width: "100%",
            borderRadius: "0.5rem",
            border: "none",
            minHeight: "50vh",
          }}
        >
          <Card.Body style={{ fontSize: "2rem" }}>
            <Card.Title>
              帳號申請單
              {showAddButton && (
                <Button
                  onClick={addApplication}
                  style={{
                    backgroundColor: common.colorCode.no_C18A13,
                    border: "none",
                    borderRadius: "1rem",
                    fontSize: "1rem",
                  }}
                  className="mx-2"
                >
                  新增申請單
                </Button>
              )}
            </Card.Title>
            <hr></hr>
            {isLoading && (
              <div style={{ textAlign: "center" }}>
                <Spinner animation="border">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
            {renderList()}
          </Card.Body>
        </Card>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <FooterComponent></FooterComponent>
        <br></br>
      </Container>
    </>
  );
}

export default ApplicationListPage;
