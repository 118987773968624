import axios from "axios";
import cookieManager from "./cookiesManager";

/**
 *
 * @param {Object} options api's options
 * @param {String} options.method api's options
 * @param {String} options.url api's options
 * @param {Object} options.body api's options
 * @param {Object} options.config api's options
 */
async function apiCaller(options, isLogin) {
  if (!isLogin) {
    const cookie = cookieManager.getCookieValue(`humanresource`);
    if (!cookie) {
      alert("待機過久請重新登入");
      window.location.href = "#/login";
    }
  }

  switch (options.method.toUpperCase()) {
    case "GET":
      return await get(options);
    case "POST":
      return await post(options);
    default:
      throw new Error(`this method only support GET and POST`);
  }

  async function get(options) {
    if (options.url) {
      const response = await axios.get(options.url);

      if (response.status === 200) {
        const data = response.data;
        if (data.code === 200) {
          return data;
        } else {
          throw new Error(
            `error code = ${data.code}, error.message = ${data.msg}`
          );
        }
      } else if (Number(response.status) === 401) {
        alert("待機過久請重新登入");
        window.location.href = "#/login";
      } else {
        throw new Error(
          `error code = ${response.statuscode}, error.message = ${response.error.message}`
        );
      }
    }
  }

  async function post(options) {
    if (options.url && options.body) {
      try {
        const response = await axios.post(
          options.url,
          options.body,
          options.config
        );

        if (response.status === 200) {
          const data = response.data;

          if (data.code === 200) {
            return data;
          } else {
            throw new Error(
              `error code = ${data.code}, error.message = ${data.msg}`
            );
          }
        } else if (Number(response.status) === 401) {
          alert("待機過久請重新登入");
          window.location.href = "#/login";
        } else if (Number(response.status) === 410) {
          alert("帳號期限已過，若需繼續使用，請聯繫系統管理員");
          window.location.href = "#/login";
        } else {
          console.log(response.status);
          console.log(response);
          throw new Error(
            `error code = ${response.status}, error.message = ${response}`
          );
        }
      } catch (error) {
        if (error.message.includes(`401`)) {
          alert("待機過久請重新登入");
          window.location.href = "#/login";
        } else if (error.message.includes(`410`)) {
          alert("帳號期限已過，若需繼續使用，請聯繫系統管理員");
          window.location.href = "#/login";
        } else {
          console.log(error);
          alert(error);
        }
      }
    }
  }
}

export default apiCaller;
