import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

/**
 * Announce Modal component
 * @param {String} title
 * @param {String} description
 * @param {Boolean} showModal
 * @param {Callback} onClose
 * @param {Callback} onHide
 * @returns {JSX} AnnounceModal
 */
function AnnounceModal({
  title,
  description = "",
  accountLife,
  showModal,
  onClose,
  onHide,
}) {
  return (
    <Modal show={showModal} onHide={onHide} style={{ color: "#5a493f" }}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Nga'ay ho親愛的使用者您好!</p>
        <p>
          為了配合資訊安全政策，您使用的機關帳號將於
          <span>{accountLife == 0 ? "今" : accountLife}</span>
          天後停用，如因業務需求需繼續使用本系統，請您使用公務信箱註冊個人使用之帳號密碼，以利保障系統個資安全，感謝您的理解與配合。
        </p>
        <p>讓我們一起為資訊安全盡一份力Aray！</p>
        <p>原住民族語言研究發展基金會 敬上</p>
        {false && <p>{description}</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          關閉
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AnnounceModal;
