// css
import "../assets/css/spinner.css";
import "../assets/css/topBuffer.css";
import "../assets/css/closeFocusBorder.css";
import "../assets/css/pageLink.css";

// img
import logoSearch from "../assets/img/logoSearch.jpg";
import SSLLogo from "../assets/img/SSLSeal.gif";
import "../assets/css/backgroundSearch.css";

// js
import React from "react";
import { useState, useEffect } from "react";
import { useContext } from "react";
import { Container, Navbar, Nav, NavLink, Card, Button } from "react-bootstrap";
import { toast } from "react-toastify";

// css
import 'react-toastify/dist/ReactToastify.min.css';

// common
import common from "../utilities/common";
import apiCaller from "../utilities/apiCaller";
import cookieManager from "../utilities/cookiesManager";

// Login context
import { LoginContext } from "../App"

toast.configure();

// hr context
export const HRContext = React.createContext({});

/**
 * human resource data page
 * @returns {JSX} HumanResourceSearch components
 */
function Layout({ className, children }) {
    document.body.style.backgroundColor = "#e0c8b3";
    document.body.style.height = "100%";
    document.body.style.margin = "0";

    const copyRight1 = `財團法人原住民族語言研究發展基金會`;
    const copyRight2 = `Indigenous Languages Research and Development Foundation`;
    const copyRight3 = `10093台北市中正區羅斯福路一段63號 電話：02-23418508#602`;
    const copyRight4 = `財團法人原住民族語言研究發展基金會 ©版權所有`;
    const copyRight5 = `Copyright © ${new Date().getFullYear()} Indigenous Languages Research and Development Foundation`;

    const warningMessageColor = `#A3432D`;
    const buttonColor = "#E75711";
    const footerColor = "#7D8E96";

    // useContext
    const loginContext = useContext(LoginContext);

    const loginData = JSON.parse(sessionStorage.getItem(`loginData`));

    let displayName = undefined;
    const [total_num, setTotal_num]  = React.useState(undefined);
    if (loginData) {
        displayName = loginData.display_name;
    }

    // get_total_num function
    useEffect(async () => {
        common.loader.show();
        const formData = new FormData();
        formData.append("auth_token", cookieManager.getCookieValue("humanresource"));

        const catch_total_num = {
            method: "POST",
            url: process.env.REACT_APP_URL + "/v1/get_total_num", // `https://ihr-api.ilrdf.org.tw/api/v1/get_total_num`,
            body: formData,
            config: { headers: { "Content-Type": 'multipart/form-data' }, },
        }
        try {
            const response = await apiCaller(catch_total_num);
            setTotal_num(response.data.total_num);

            if (!response) {
                throw new Error(`request error => response is undefined or empty, response == ${response}`);
            }
        }
        catch (error) {
            console.log(error);
            alert(`${error.message} total_num miss`);
        }
        finally {
            common.loader.close();
        }
    }, [])

    // logout function
    async function logout(eventKey) {
        await cookieManager.setCookie(`humanresource`, ``);
        sessionStorage.clear();
        window.location.href = `/`;
    }

    const instruction = () => {
        window.location.href = '#/instructions';
    }

    // alert if not implement yet
    const search = () => {
        window.location.href = '#/humanresourcesearch';
    }

    const refreshPage = () => {
        sessionStorage.removeItem(`data`);
        sessionStorage.removeItem(`selectedData`);
        sessionStorage.removeItem(`active`);
        sessionStorage.removeItem(`searchCondition`);
        sessionStorage.removeItem(`message`);
        window.location.href = `#/humanresourcesearch`;
    }



    return (
        <>
            {/* wrapper */}
            <div style={{
                height: "100%",
            }}>
                {/* header */}
                <Navbar className={className} style={{ backgroundColor: "#fff", maxHeight: "40%" }} expand="lg">
                    <Container
                    /* add this will made the navbar's content be center */
                    >
                        <Navbar.Brand style={{ width: "50%" }}>
                            <a href={`#`} onClick={refreshPage}>
                                <img
                                    src={`${logoSearch}`}
                                    width={`100%`}
                                    height={`auto`}
                                    className="d-inline-block align-top"
                                />
                            </a>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll" className="justify-content-end">
                            <div className={"d-flex flex-column justify-content-between"}>
                                <Nav className="align-self-end">
                                    <Nav.Item>
                                        <a href="https://ihr.ilrdf.org.tw/download/instructions.pdf" download="原住民族語言人才資料庫-使用說明手冊"><Button variant="primary">使用說明手冊下載</Button>{' '}</a>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link style={{ color: "black", fontWeight: "500" }} eventKey="search" onSelect={search}>
                                            {"搜尋"}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item style={{ float: "right", textAlign: "right" }}>
                                        <NavLink as={"div"} style={{ color: "black", fontWeight: "500" }}>
                                            {" | "}
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link style={{ color: "black", fontWeight: "500" }} eventKey="description" onSelect={instruction}>
                                            {"說明"}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Nav className="align-self-end">
                                    <Nav.Item>
                                        <NavLink as={"div"} style={{ color: common.colorCode.no_717171, fontWeight: "900" }}>
                                            <span className="material-icons">person</span> {`${displayName ?? "原語會人員"}`}
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item style={{ float: "right", textAlign: "right" }}>
                                        <NavLink as={"div"} style={{ color: "black", fontWeight: "900" }}>
                                            {" | "}
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item className="align-self-end">
                                        <NavLink style={{ color: common.colorCode.no_A3432D, fontWeight: "900" }} eventKey="logout" onSelect={logout}>
                                            {"登出"}
                                        </NavLink>
                                    </Nav.Item>
                                </Nav>
                                <Nav className="align-self-end">
                                    <Nav.Item className="align-self-end">
                                        <NavLink style={{ color: common.colorCode.no_A3432D, fontWeight: "900" }}>
                                            {`本資料庫共收錄`}
                                            {<strong> {`${total_num}`} </strong>}
                                            {`筆資料`}
                                        </NavLink>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                {/* body */}
                <div style={{
                    minHeight: "60%",
                    //backgroundColor: "#FFFFFF"
                }}>
                    {children}
                </div>

                {/* footer */}
                <div style={{
                    bottom: 0,
                }}>
                    <Card className={"text-center"} style={{
                        //position: "fixed", bottom: 0, textAlign: "center",
                        color: footerColor, fontSize: "0.8em", backgroundColor: "transparent", border: "none",
                    }}>
                        <Card.Body>
                            <Card.Text>
                                <div>{copyRight1}</div>
                                <div>{copyRight2}</div>
                                <div>{copyRight3}</div>
                            </Card.Text>
                            <Card.Text>
                                <div>{copyRight4}</div>
                                <div>{copyRight5}</div>
                            </Card.Text>
                            <Card.Text>
                                <a href="javascript:location.href='https://publicca.hinet.net/SSLQueryCert/SSLQueryCert.jsp?Domain_name=ihr.ilrdf.org.tw'"><img src={SSLLogo} width="50" height="70" /></a>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    );
}

export default Layout;
