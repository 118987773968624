/**
 * 產生一個會 fixed 在右下方的公告
 * @param {String} 公告文字
 * @returns {react component} Announcement.js
 */
export default function Announcement({ announcementText, accountLife, style }) {
  return (
    <div
      class="alert alert-danger"
      style={{
        position: "fixed",
        top: "80vh",
        right: "2vw",
        width: "25vw",
        whiteSpace: "pre-line",
        padding: "0.5rem",
        ...style,
      }}
    >
      您的帳號使用期限剩餘{accountLife == 0 ? "今" : accountLife}
      天，請盡快透過帳號申請平台申請新帳號。
      {false && (announcementText ? announcementText : "無公告")}
    </div>
  );
}
